import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import { configInfo } from "@/api";
export default new Vuex.Store({
    state: {
        // 网站名称
        WebsiteName: "",
        // 网站LOGO
        PcLogo: "",
        // 微信二维码
        WebSiteQrcode: "",
        // 小程序二维码
        WechatMiniProgramQrcode: "",
        // 公司地址
        CompanyAddress: {},
        // 联系方式
        ContactPhone: [],
        // 企业邮箱
        CompanyEmail: [],
        // 首页标题
        WebSiteTitle: "",
        // 首页关键词
        WebSiteKeywords: "",
        // 首页描述
        WebSiteDesprition: "",
        // 备案号
        BeiAn: "",
        // 备案号链接
        BeiAnUrl: "",
        // 公安备案号
        GonGan: "",
        // 公安备案号链接
        GonGanUrl: "",
        // 版权信息
        BanQuanXinXi: "",
        // 是否开启悼念模式
        MourningMod: false,
        // 网站状态
        WebOpenStatus: true,
    },
    getters: {
        getWebsiteName: (state) => {
            return state.WebsiteName;
        },
        getPcLogo: (state) => {
            return state.PcLogo;
        },
        getWebSiteQrcode: (state) => {
            return state.WebSiteQrcode;
        },
        getWechatMiniProgramQrcode: (state) => {
            return state.WechatMiniProgramQrcode;
        },
        getCompanyAddress: (state) => {
            return state.CompanyAddress;
        },
        getContactPhone: (state) => {
            return state.ContactPhone;
        },
        getCompanyEmail: (state) => {
            return state.CompanyEmail;
        },
        getWebSiteTitle: (state) => {
            return state.WebSiteTitle;
        },
        getWebSiteKeywords: (state) => {
            return state.WebSiteKeywords;
        },
        getWebSiteDesprition: (state) => {
            return state.WebSiteDesprition;
        },
        getBeiAn: (state) => {
            return state.BeiAn;
        },
        getBeiAnUrl: (state) => {
            return state.BeiAnUrl;
        },
        getGonGan: (state) => {
            return state.GonGan;
        },
        getGonGanUrl: (state) => {
            return state.GonGanUrl;
        },
        getBanQuanXinXi: (state) => {
            return state.BanQuanXinXi;
        },
        getMourningMod: (state) => {
            return state.MourningMod;
        },
        getWebOpenStatus: (state) => {
            return state.WebOpenStatus;
        }
    },
    mutations: {
        setWebsiteName: function(state, WebsiteName) {
            state.WebsiteName = WebsiteName;
        },
        setPcLogo: function(state, PcLogo) {
            state.PcLogo = PcLogo;
        },
        setWebSiteQrcode: function(state, WebSiteQrcode) {
            state.WebSiteQrcode = WebSiteQrcode;
        },
        setWechatMiniProgramQrcode: function(state, WechatMiniProgramQrcode) {
            state.WechatMiniProgramQrcode = WechatMiniProgramQrcode;
        },
        setCompanyAddress: function(state, CompanyAddress) {
            state.CompanyAddress = CompanyAddress;
        },
        setContactPhone: function(state, ContactPhone) {
            state.ContactPhone = ContactPhone;
        },
        setCompanyEmail: function(state, CompanyEmail) {
            state.CompanyEmail = CompanyEmail;
        },
        setWebSiteTitle: function(state, WebSiteTitle) {
            state.WebSiteTitle = WebSiteTitle;
        },
        setWebSiteKeywords: function(state, WebSiteKeywords) {
            state.WebSiteKeywords = WebSiteKeywords;
        },
        setWebSiteDesprition: function(state, WebSiteDesprition) {
            state.WebSiteDesprition = WebSiteDesprition;
        },
        setBeiAn: function(state, BeiAn) {
            state.BeiAn = BeiAn;
        },
        setBeiAnUrl: function(state, BeiAnUrl) {
            state.BeiAnUrl = BeiAnUrl;
        },
        setGonGan: function(state, GonGan) {
            state.GonGan = GonGan;
        },
        setGonGanUrl: function(state, GonGanUrl) {
            state.GonGanUrl = GonGanUrl;
        },
        setBanQuanXinXi: function(state, BanQuanXinXi) {
            state.BanQuanXinXi = BanQuanXinXi;
        },
        setMourningMod: function(state, MourningMod) {
            state.MourningMod = MourningMod;
        },
        setWebOpenStatus: function(state, WebOpenStatus) {
            state.WebOpenStatus = WebOpenStatus;
        },
    },
    actions: {
        // 获取全局配置信息
        async getGlobalConfig({ commit }) {
            let res = await configInfo();
            if (res && res.code == 200 && res.data && res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    let item = res.data[i];
                    if (item.config_key == "WEB_SITE_QRCODE") {
                        if (item.config_value) {
                            commit("setWebSiteQrcode", JSON.parse(item.config_value).img);
                        }
                        continue;
                    } else if (item.config_key == "WEBSITE_TITLE") {
                        commit("setWebSiteTitle", item.config_value);
                        continue;
                    } else if (item.config_key == "WEBSITE_KEYWORDS") {
                        commit("setWebSiteKeywords", item.config_value);
                        continue;
                    } else if (item.config_key == "WEBSITE_DESCRIPTION") {
                        commit("setWebSiteDesprition", item.config_value);
                        continue;
                    } else if (item.config_key == "BEIAN") {
                        commit("setBeiAn", item.config_value);
                        continue;
                    } else if (item.config_key == "BEIANURL") {
                        commit("setBeiAnUrl", item.config_value);
                        continue;
                    } else if (item.config_key == "GONGAN") {
                        commit("setGonGan", item.config_value);
                        continue;
                    } else if (item.config_key == "GONGANURL") {
                        commit("setGonGanUrl", item.config_value);
                        continue;
                    } else if (item.config_key == "BANQUANXINXI") {
                        commit("setBanQuanXinXi", item.config_value);
                        continue;
                    } else if (item.config_key == "MOURNING_MOD") {
                        commit("setMourningMod", item.config_value == "0" ? false : true);
                        continue;
                    } else if (item.config_key == "WEB_OPEN_STATUS") {
                        commit("setWebOpenStatus", item.config_value == "1" ? true : false);
                        continue;
                    } else if (item.config_key == "WEBSITE_NAME") {
                        commit("setWebsiteName", item.config_value);
                        continue;
                    } else if (item.config_key == "PCLOGO") {
                        if (item.config_value) {
                            commit("setPcLogo", JSON.parse(item.config_value).img);
                        }
                        continue;
                    } else if (item.config_key == "WECHAT_MINI_PROGRAM_QRCODE") {
                        if (item.config_value) {
                            commit("setWechatMiniProgramQrcode", JSON.parse(item.config_value).img);
                        }
                        continue;
                    } else if (item.config_key == "COMPANY_ADDRESS") {
                        if (item.config_value) {
                            commit("setCompanyAddress", JSON.parse(item.config_value));
                        }
                        continue;
                    } else if (item.config_key == "CONTACT_PHONE") {
                        if (item.config_value) {
                            commit("setContactPhone", JSON.parse(item.config_value));
                        }
                        continue;
                    } else if (item.config_key == "COMPANY_EMAIL") {
                        if (item.config_value) {
                            commit("setCompanyEmail", JSON.parse(item.config_value));
                        }
                        continue;
                    }
                }
            }
        },
    },
});